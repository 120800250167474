import { BaseModel as BaseBackboneModel } from 'outlinejs-backbone-model/lib/models';

import { conf } from 'outlinejs';
import OAuth2 from '../auth/oauth';
import { LanguageStorage } from './storage';

export function mergeBackboneOptions(options, newOptions) {
  Object.keys(newOptions).forEach((key) => {
    //eslint-disable-next-line
    if (!options.hasOwnProperty(key)) {
      options[key] = newOptions[key];
    } else {
      Object.assign(options[key], newOptions[key]);
    }
  });
  return options;
}

export async function oauth2Sync(syncFunction, method, model, options = {}) {
  const token = OAuth2.loadToken();
  const currentLanguage = await LanguageStorage.getLanguage();
  options.headers = options.headers || {};
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  options.headers.Accept = `application/json; version=${conf.settings.API_VERSION}`;
  options.headers['Accept-Language'] = currentLanguage;
  options.headers['x-api-key'] = conf.settings.X_API_KEY;

  //eslint-disable-next-line
  if (options && !options.hasOwnProperty('data')) {
    options.data = {};
  }
  options.data = Object.assign(options.data, {
    softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
  });
  return syncFunction(method, model, options);
}

export class BaseModel extends BaseBackboneModel {
  sync(method, model, options = {}) {
    return oauth2Sync(super.sync, method, model, options);
  }
}
