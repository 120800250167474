import React from 'react';
import PropTypes from 'prop-types';

export const ColorCorrectionPriceSet = ({ price, discountedPrice }) => {
  return (
    <div>
      {price}
      {discountedPrice ? (
        <div>
          <del className="color-primary">
            <span className="full-price" style={{ fontWeight: 400 }}>
              {discountedPrice}
            </span>
          </del>
        </div>
      ) : null}
    </div>
  );
};

ColorCorrectionPriceSet.propTypes = {
  price: PropTypes.string,
  discountedPrice: PropTypes.string
};
