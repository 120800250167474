import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'outlinejs';
import { AeModal } from '@photosi/albumepoca-ui';

export class CouponWarningAlert extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.storageKey = props.storageKey;
    this.state = {
      couponWarningDialogCheckBox: false
    };

    this.onChangeCouponWarningDialogVisibility =
      this.onChangeCouponWarningDialogVisibility.bind(this);
  }

  onChangeCouponWarningDialogVisibility() {
    const { storageKey } = this.props;
    const { couponWarningDialogCheckBox } = this.state;
    const newValue = !couponWarningDialogCheckBox;

    this.setState({
      couponWarningDialogCheckBox: newValue
    });

    if (newValue) {
      localStorage.setItem(storageKey, 'yes');
    } else {
      localStorage.removeItem(storageKey);
    }
  }

  close() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { isOpen, message } = this.props;
    const { couponWarningDialogCheckBox } = this.state;
    const customClasses = {
      'modal-md': true,
      'modal-attention': true
    };
    const title = this.i18n.gettext('Attention');
    const template = (
      <div className="custom-template">
        <h4 className="modal-title">{title}</h4>
        <hr />
        {message}
        <hr />
        <div className="modal-footer">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 text-center" />
            <div className="col-sm-6 col-md-6 col-lg-6 text-center">
              <a className="basic__cta__btns" onClick={this.close.bind(this)}>
                {this.i18n.gettext('I understand')}
              </a>
            </div>
          </div>
        </div>
        <div>
          <form className="form-inline">
            <input
              type="checkbox"
              checked={couponWarningDialogCheckBox}
              onChange={this.onChangeCouponWarningDialogVisibility}
            />
            <span className="help-block" style={{ paddingLeft: '5px' }}>
              {this.i18n.gettext("Doesn't show this message again")}
            </span>
          </form>
        </div>
      </div>
    );

    return (
      <AeModal
        isOpen={isOpen}
        title={title}
        customClasses={customClasses}
        customTemplate={template}
        onClose={this.close.bind(this)}
      />
    );
  }
}

CouponWarningAlert.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  storageKey: PropTypes.string,
  onClose: PropTypes.func
};
