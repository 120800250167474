import React from 'react';
import { components } from 'outlinejs';
import { AeModal } from '@photosi/albumepoca-ui';
import PropTypes from 'prop-types';
import { convertSvgContent, svgToPng } from '@photosi/svg-converter-js';

import DownloadImg from '../media/images/download.png';

export class PreviewModal extends components.BaseComponent {
  constructor(props) {
    super(props);

    // this.downloadImage = this.downloadImage.bind(this);
  }

  downloadImage(url, fileName) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  async downloadSvg(svg) {
    const convertedSvg = await convertSvgContent(svg);
    svgToPng(convertedSvg);
  }

  scaleSvg(svg) {
    const doc = new DOMParser().parseFromString(svg, 'text/html');
    const svgElement = doc.getElementsByTagName('svg')[0];
    svgElement.setAttribute('width', '100%');
    svgElement.setAttribute('height', '100%');
    const s = new XMLSerializer();
    return s.serializeToString(svgElement);
  }

  close() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { isOpen, imagePreviewUrl, svgPreview, enableImageDownload, enableSvgDownload } =
      this.props;
    const modalClasses = {
      'modal-lg': true,
      'modal-img-preview': true
    };

    if (imagePreviewUrl) {
      const cartModalStyles = {
        background: `url(${imagePreviewUrl})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '100%',
        height: '90%'
      };

      const customTemplate = (
        <div className="image-holder">
          <div className="image-wrapper" style={cartModalStyles} />
          {enableImageDownload && (
            <div
              className="btn--success"
              onClick={this.downloadImage.bind(this, imagePreviewUrl, 'cart')}>
              <img alt={'Download'} src={DownloadImg} />
              {this.i18n.gettext('Download image')}
            </div>
          )}
        </div>
      );

      return (
        <AeModal
          isOpen={isOpen}
          customClasses={modalClasses}
          customTemplate={customTemplate}
          onClose={this.close.bind(this)}
          shouldCloseOnOverlayClick
        />
      );
    }

    if (svgPreview) {
      const width = `${100}%`;
      const height = `${90}%`;
      const previewSvgContent = this.scaleSvg(svgPreview);

      const customTemplate = (
        <div className="image-holder">
          <div
            className="svg-container"
            dangerouslySetInnerHTML={{ __html: previewSvgContent }}
            style={{ width, height }}
          />
          {enableSvgDownload && (
            <div className="btn--success" onClick={this.downloadSvg.bind(this, svgPreview)}>
              <img alt={'Download'} src={DownloadImg} />
              {this.i18n.gettext('Download image')}
            </div>
          )}
        </div>
      );

      return (
        <AeModal
          isOpen={isOpen}
          customClasses={modalClasses}
          customTemplate={customTemplate}
          onClose={this.close.bind(this)}
          shouldCloseOnOverlayClick
        />
      );
    }

    return null;
  }
}

PreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  imagePreviewUrl: PropTypes.string,
  svgPreview: PropTypes.string,
  enableImageDownload: PropTypes.bool,
  enableSvgDownload: PropTypes.bool,
  onClose: PropTypes.func
};
