import React from 'react';
import { components } from 'outlinejs';
import { AeModal } from '@photosi/albumepoca-ui';
import PropTypes from 'prop-types';

export class AlertWithHideButton extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      checkbox: false
    };
  }

  onChangeHideButton() {
    const { storageKey } = this.props;
    const { checkbox } = this.state;
    const newCheckboxValue = !checkbox;

    this.setState({ checkbox: newCheckboxValue });

    if (newCheckboxValue) {
      localStorage.setItem(storageKey, 'yes');
    } else {
      localStorage.removeItem(storageKey);
    }
  }

  close() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { title, message, isOpen } = this.props;
    const { checkbox } = this.state;

    return (
      <AeModal
        isOpen={isOpen}
        customClasses={{
          'modal-md': true,
          'modal-attention': true
        }}
        customTemplate={
          <div className="alert-with-hide-button-template">
            {title && (
              <h4 className="modal-title" style={{ marginBottom: '20px' }}>
                {title}
              </h4>
            )}
            <div style={{ marginBottom: '40px' }}>{message}</div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 text-center" />
                <div className="col-sm-6 col-md-6 col-lg-6 text-center">
                  <a className="basic__cta__btns" onClick={this.close.bind(this)}>
                    {this.i18n.gettext('I understand')}
                  </a>
                </div>
              </div>
            </div>
            <div>
              <form className="form-inline">
                <input
                  type="checkbox"
                  checked={checkbox}
                  onChange={this.onChangeHideButton.bind(this)}
                />
                <span
                  className="help-block"
                  style={{
                    fontSize: '14px',
                    display: 'inline-block',
                    paddingLeft: '10px'
                  }}>
                  {this.i18n.gettext("Doesn't show this message again")}
                </span>
              </form>
            </div>
          </div>
        }
        onClose={this.close.bind(this)}
      />
    );
  }
}

AlertWithHideButton.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  storageKey: PropTypes.string,
  onClose: PropTypes.func
};
