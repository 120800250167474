export class HttpRequestError extends Error {
  constructor(...args) {
    super(...args);
    this.name = `${args[0]} HttpRequestError`;
    this.message = args[1];
    try {
      Error.captureStackTrace(this, HttpRequestError);
    } catch (e) {} //eslint-disable-line
  }
}

export class VoucherError extends Error {
  constructor(...args) {
    super(...args);
    this.name = args[0];
    try {
      Error.captureStackTrace(this, CustomError);
    } catch (e) {} //eslint-disable-line
  }
}

export class CustomError extends Error {
  constructor(...args) {
    super(...args);
    const originalError = args[0];

    if (typeof originalError.code !== 'undefined') {
      let message = 'HTTP REQUEST GENERIC ERROR';
      if (originalError.errorText && originalError.errorText !== '') {
        message = originalError.errorText;
      }
      if (originalError.errorJSON && originalError.errorJSON.message) {
        message = originalError.errorJSON.message;
      }

      this.name = `${originalError.code} HttpRequestError`;
      this.message = message;
    } else {
      this.name = originalError.name;
      this.message = originalError.message;
    }
    try {
      Error.captureStackTrace(this, CustomError);
    } catch (e) {} //eslint-disable-line
  }
}
