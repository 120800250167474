import { runtime } from '@outlinejs/contexts';
import Cookies from 'js-cookie';
import { conf, routing } from 'outlinejs';
import url from 'url';
import RetryOnError from 'retry-on-error-js';
import Logger from '../logger';

class HttpTimeoutErrorHandlerStrategy {
  static create() {
    return new HttpTimeoutErrorHandlerStrategy();
  }

  canCatch(error) {
    //eslint-disable-next-line
    if (error && error.hasOwnProperty('code') && [502, 503, 504].indexOf(error.code) !== -1) {
      return true;
    }
    return false;
  }
}

export async function promiseRetry(asyncFunction, errorMessage = 'Promise Retry', context = {}) {
  return await RetryOnError.runExponential(asyncFunction, context, {
    maxTries: 2,
    exponentialBase: 2,
    multiplier: 3,
    errorHandlerStrategy: new HttpTimeoutErrorHandlerStrategy(),
    logStrategy(e, { attempts, lastDelayTime }) {
      Logger.warning(errorMessage, {
        errorName: e.name,
        errorStack: e.stack,
        errorMessage: e.message,
        errorCode: e.hasOwnProperty('code') ? e.code : '', //eslint-disable-line
        attempt: attempts,
        delay: lastDelayTime,
        ...context
      });
    }
  });
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Returns true if impersonation is active
 * */
export function impersonationActive() {
  return !!Cookies.get(conf.settings.CUSTOMER_AUTH_COOKIE_KEY);
}

/**
 * Update query string parameter
 * */
export function updateQueryStringParameter(uri, key, value) {
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

/**
 * Function used to navigate to an absolute url or an internal route
 * @param {object} request - OutlineJs request.
 * @param {object} response - OutlineJs response.
 * @param {string} to - destination url or state.
 * @param {object} stateParams - params used in state url definition.
 * @param {boolean} replaceHistory - if true replace previous history state.
 * @param {object} queryParams - query string params added to url.
 */
export function navigateTo(
  request,
  response,
  to,
  stateParams = {},
  replaceHistory = false,
  queryParams = null
) {
  let destinationUrl;
  let absoluteUrl = false;
  let addParams = stateParams && stateParams !== {};
  try {
    destinationUrl = routing.Utils.reverse(to, request, stateParams);
    addParams = false; //router already added query params
  } catch (ex) {
    destinationUrl = to;
  }

  if (runtime.isClient) {
    // add query params if necessary
    if (addParams) {
      for (let queryParam in stateParams) {
        destinationUrl = updateQueryStringParameter(
          destinationUrl,
          queryParam,
          stateParams[queryParam]
        );
      }
    }

    if (queryParams) {
      for (let queryParam in queryParams) {
        destinationUrl = updateQueryStringParameter(
          destinationUrl,
          queryParam,
          queryParams[queryParam]
        );
      }
    }

    // check if it's an absolute url
    if (url.parse(destinationUrl).protocol) {
      absoluteUrl = true;
    }

    if (conf.settings.ROUTING_USE_FRAGMENT) {
      let hasher = require('hasher');
      hasher.setHash(destinationUrl);
    } else {
      if (conf.settings.SERVER_SIDE_LINK_ONLY) {
        window.location.href = destinationUrl;
      } else {
        let history = require('html5-history-api');
        if (replaceHistory) {
          try {
            history.replaceState(null, null, destinationUrl);
          } catch (err) {
            window.location.replace(destinationUrl);
            return;
          }
        } else {
          history.pushState(null, null, destinationUrl);
        }

        if (absoluteUrl) {
          window.location.href = destinationUrl;
        } else {
          window.navigateEventEmitter.emit('navigate', destinationUrl);
        }
      }
    }
  } else {
    response.writeHead(302, { Location: destinationUrl });
    response.end();
  }
}
