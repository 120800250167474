import { conf } from 'outlinejs';
import amplitude from 'amplitude-js';
import { impersonationActive } from './utils';
import axios from 'axios';
import Logger from './logger';

async function getExchangeRate(currency) {
  // fix backend wrong currency code
  if (currency === 'lst') {
    currency = 'gbp';
  }

  const o_date = new Intl.DateTimeFormat();
  const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
  const m_date = o_date.formatToParts().reduce(f_date, {});
  const response = await axios.get(
    `${conf.settings.PRICING_EXCHANGE_RATES_URL}${m_date.year}-${m_date.month}-${m_date.day}`,
    {
      headers: {
        'x-api-key': conf.settings.X_API_KEY
      }
    }
  );
  return response.data['rates'][currency];
}

function convertToEurPrice(price, exchangeRate) {
  const convertedPrice = price / exchangeRate;
  return Math.round(convertedPrice * 100) / 100;
}

export function productRevenueInterface(productType) {
  let productRevenue;

  switch (productType) {
    case 'AEGuide':
    case 'AE_GUIDE':
      productRevenue = 'AEGuide';
      break;
    case 'Album':
    case 'ALBUM':
      productRevenue = 'Album';
      break;
    case 'AlbumPackaging':
    case 'ALBUM_PACKAGING':
      productRevenue = 'AlbumPackaging';
      break;
    case 'EVENT_BOOK':
    case 'EventBook':
      productRevenue = 'EventBook';
      break;
    case 'EVENT_BOOK_PACKAGING':
    case 'EventBookPackaging':
      productRevenue = 'EventBookPackaging';
      break;
    case 'MattedPrints':
    case 'MATTED_PRINTS':
      productRevenue = 'MattedPrints';
      break;
    case 'MattedPrintsPackaging':
    case 'MATTED_PRINTS_PACKAGING':
      productRevenue = 'MattedPrintsPackaging';
      break;
    case 'MattedPrintsStand':
    case 'MATTED_PRINTS_STAND':
      productRevenue = 'MattedPrintsStand';
      break;
    case 'UsbDrive':
    case 'USB_DRIVE':
      productRevenue = 'UsbDrive';
      break;
    case 'UsbDrivePackaging':
    case 'USB_DRIVE_PACKAGING':
      productRevenue = 'UsbDrivePackaging';
      break;
    case 'HomeDecor':
    case 'HOMEDECOR':
      productRevenue = 'Wall&TableDecor';
      break;
    case 'COLOR_CORRECTION':
      productRevenue = 'ColorCorrection';
      break;
    case 'FULL_SERVICE_DESIGN':
      productRevenue = 'FullServiceDesign';
      break;
    default:
      console.error(`${productType} productRevenueInterface not implemented`);
      Logger.error(`${productType} productRevenueInterface not implemented`);
  }

  return productRevenue;
}

export function productCategoryInterface(productType) {
  let productCategory;

  switch (productType) {
    case 'AEGuide':
    case 'AE_GUIDE':
      productCategory = 'AEGuide';
      break;
    case 'Album':
    case 'ALBUM':
    case 'AlbumPackaging':
    case 'ALBUM_PACKAGING':
      productCategory = 'Album';
      break;
    case 'EVENT_BOOK':
    case 'EventBook':
    case 'EVENT_BOOK_PACKAGING':
    case 'EventBookPackaging':
      productCategory = 'EventBook';
      break;
    case 'MattedPrints':
    case 'MATTED_PRINTS':
    case 'MattedPrintsPackaging':
    case 'MATTED_PRINTS_PACKAGING':
    case 'MattedPrintsStand':
    case 'MATTED_PRINTS_STAND':
      productCategory = 'MattedPrints';
      break;
    case 'UsbDrive':
    case 'USB_DRIVE':
    case 'UsbDrivePackaging':
    case 'USB_DRIVE_PACKAGING':
      productCategory = 'Usb&Box';
      break;
    case 'HomeDecor':
    case 'HOMEDECOR':
      productCategory = 'Wall&TableDecor';
      break;
    case 'COLOR_CORRECTION':
      productCategory = 'ColorCorrection';
      break;
    case 'FULL_SERVICE_DESIGN':
      productCategory = 'FullServiceDesign';
      break;
    default:
      console.error(`${productType} productCategoryInterface not implemented`);
      Logger.error(`${productType} productCategoryInterface not implemented`);
  }

  return productCategory;
}

const EVENTS_QUEUE = [];

export default class EventTracker {
  static get active() {
    // Don't initialize class for Datadog automatic tests and for staff users
    return window._DATADOG_SYNTHETICS_BROWSER === undefined && !impersonationActive();
  }

  static initialize() {
    amplitude.getInstance().init(conf.settings.AMPLITUDE_API_KEY, null, {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true
    });
    if (EVENTS_QUEUE.length !== 0) {
      EVENTS_QUEUE.forEach((eventCallback) => {
        eventCallback();
      });
      EVENTS_QUEUE.length = 0;
    }
  }

  static removeTrackingForUser() {
    if (EventTracker.active) {
      amplitude.getInstance().setUserId(null);
      amplitude.getInstance().clearUserProperties();
      amplitude.getInstance().regenerateDeviceId();
    }
  }

  static addUserInfo(user) {
    if (user) {
      const userProps = {
        payment_type: user.paymentType,
        membership_type:
          user.membershipInfo &&
          user.membershipInfo['isActive'] &&
          user.membershipInfo['membershipCode']
            ? user.membershipInfo['membershipCode']
            : null
      };

      amplitude.getInstance().setUserId(user.shopCode);
      amplitude.getInstance().setUserProperties(userProps);
    }
  }

  static log(user, eventName, eventProperties) {
    const eventCallback = () => {
      if (EventTracker.active) {
        EventTracker.addUserInfo(user);
        amplitude.getInstance().logEvent(eventName, eventProperties);
      }
    };

    if (window.AMPLITUDE_CONSENT_ENABLED === undefined) {
      EVENTS_QUEUE.push(eventCallback);
      return;
    }

    if (window.AMPLITUDE_CONSENT_ENABLED === false) {
      return;
    }

    eventCallback();
  }

  static async logPurchase(user, order, orderRows) {
    if (EventTracker.active) {
      // temporary disable event for currencies different from EUR
      let exchangeRate = 1;
      if (order.currencyCode !== 'EUR') {
        try {
          exchangeRate = await getExchangeRate(order.currencyCode.toLowerCase());
        } catch (error) {
          Logger.error('Unable to retrieve exchange rate', { error });
          return;
        }
      }

      const orderId = order.orderId;
      const currencyCode = order.currencyCode;
      const defaultEventProps = {
        order_id: orderId,
        currency_code: currencyCode
      };

      // LOG user_purchase event
      EventTracker.log(
        user,
        'user_purchase',
        Object.assign({}, defaultEventProps, {
          price: convertToEurPrice(order.price, exchangeRate),
          payment_type_id: order.paymentType
        })
      );

      // LOG Revenue event
      EventTracker.addUserInfo(user);

      // log products
      orderRows.forEach((orderRow) => {
        EventTracker.logRevenue(
          productRevenueInterface(orderRow.productCode),
          convertToEurPrice(orderRow.unitPrice, exchangeRate),
          orderRow.quantity,
          'product',
          Object.assign({}, defaultEventProps, {
            original_price: orderRow.unitPrice,
            product_category: productCategoryInterface(orderRow.productCode)
          })
        );
      });

      // log discount
      const discountProduct = 'Discount';
      const discountRevenueType = 'discount';
      EventTracker.logRevenue(
        discountProduct,
        -convertToEurPrice(order.totalDiscount, exchangeRate),
        1,
        discountRevenueType,
        Object.assign({}, defaultEventProps, {
          original_price: -order.totalDiscount,
          coupons_number: order.couponCodes.length
        })
      );

      // log shipping fees
      const shippingFeesProduct = 'ShippingFees';
      const shippingFeesRevenueType = 'shipping_fees';
      EventTracker.logRevenue(
        shippingFeesProduct,
        convertToEurPrice(order.shippingCost, exchangeRate),
        1,
        shippingFeesRevenueType,
        Object.assign({}, defaultEventProps, {
          original_price: order.shippingCost
        })
      );

      // log tax
      const taxProduct = 'Tax';
      const taxRevenueType = 'tax';
      EventTracker.logRevenue(
        taxProduct,
        convertToEurPrice(order.vat, exchangeRate),
        1,
        taxRevenueType,
        Object.assign({}, defaultEventProps, {
          original_price: order.vat
        })
      );
    }
  }

  static logRevenue(product, euroPrice, quantity, revenueType, eventProps) {
    const revenue = new amplitude.Revenue()
      .setProductId(product)
      .setPrice(euroPrice)
      .setQuantity(quantity)
      .setRevenueType(revenueType)
      .setEventProperties(eventProps);

    amplitude.getInstance().logRevenueV2(revenue);
  }
}
