import { runtime } from '@outlinejs/contexts';
import { conf } from 'outlinejs';

import { LanguageStorage } from './storage';

export default class {
  async processRequest(request) {
    if (runtime.isClient) {
      // set language
      let currentLanguage;
      const querystringLanguage = request.query.language;
      currentLanguage = await LanguageStorage.getLanguage();

      if (querystringLanguage) {
        if (conf.settings.LANGUAGES.indexOf(querystringLanguage) > -1) {
          currentLanguage = querystringLanguage;
          LanguageStorage.setLanguage(currentLanguage);
        }
      }
      request.language = currentLanguage;
    } else {
      request.language = conf.settings.DEFAULT_LANGUAGE;
    }
  }
}
