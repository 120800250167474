import React from 'react';
import PropTypes from 'prop-types';
import { components, conf } from 'outlinejs';
import { AeModal } from '@photosi/albumepoca-ui';
import Logger from '../logger';
import EventTracker from '../eventTracker';

const ERROR_TYPES = [
  'VoucherCodeNotExists',
  'VoucherDateValidity',
  'ProfessionalUserListCondition',
  'VoucherCodeBurned',
  'NumberOfAppliedVouchers',
  'VoucherCodeExisting',
  'VoucherType',
  'IncompatibleCampaign',
  'ProfessionalUserRegistrationDatePeriodCondition',
  'ProfessionalUserVatCondition',
  'NumberOfUsesRangeCondition',
  'TotalOrderRangeCondition',
  'NumberOfProductsWithAllOptionValuesCondition',
  'NumberOfPagesRangeCondition',
  'NumberOfConfirmedOrderCondition',
  'VoucherNumberOfUsesRangeCondition',
  'OneShotVoucherCodeBurned',
  'NumberOfProductsWithAllOptionValuesCampaignProductCondition'
];

export class CouponErrorAlert extends components.BaseComponent {
  close() {
    const { onClose } = this.props;
    onClose();
  }

  openPromoFaq(faqUrl, voucher) {
    window.open(faqUrl, '_blank');

    EventTracker.log(this.customerUser, 'promo_faq_opened', {
      promo_code_id: voucher.campaignCode
    });
  }

  getErrorMessages(errorTypes, voucher, language) {
    let date;
    let errorMessages = [];

    errorTypes.forEach((errorType) => {
      if (!ERROR_TYPES.includes(errorType)) {
        Logger.warning(`Voucher error ${errorType} not implemented`);
      }

      let errorMessage;
      switch (errorType) {
        case 'VoucherCodeNotExists':
          errorMessage = this.i18n.gettext('il voucher non esiste');
          break;
        case 'VoucherDateValidity':
          errorMessage = this.i18n.gettext('il voucher è scaduto il {EXPIRE_DATE}');
          date = new Date(voucher.expireDate || voucher.endCampaignDate).toLocaleDateString(
            language
          );
          errorMessage = errorMessage.replace('{EXPIRE_DATE}', date);
          break;
        case 'ProfessionalUserListCondition':
          errorMessage = this.i18n.gettext("l'utente non è autorizzato ad applicare il voucher");
          break;
        case 'VoucherCodeBurned':
        case 'OneShotVoucherCodeBurned':
          if (voucher.usedDate) {
            errorMessage = this.i18n.gettext('il voucher è già stato applicato il {USED_DATE}');
            date = new Date(voucher.usedDate).toLocaleDateString(language);
            errorMessage = errorMessage.replace('{USED_DATE}', date);
          } else {
            errorMessage = this.i18n.gettext('il voucher è già stato applicato');
          }
          break;
        case 'NumberOfAppliedVouchers':
          errorMessage = this.i18n.gettext('raggiunto limite di voucher applicati');
          break;
        case 'VoucherCodeExisting':
          errorMessage = this.i18n.gettext('il voucher è già stato applicato');
          break;
        case 'VoucherType':
          errorMessage = this.i18n.gettext('la tipologia di voucher è già stata applicata');
          break;
        case 'IncompatibleCampaign':
          errorMessage = this.i18n.gettext('il voucher non è cumulabile');
          break;
        case 'ProfessionalUserRegistrationDatePeriodCondition':
          errorMessage = this.i18n.gettext("i termini per l'applicazione del voucher sono scaduti");
          break;
        case 'TotalOrderRangeCondition':
          errorMessage = this.i18n.gettext(
            "non sono rispettate le condizioni minime (di valore) per l'applicazione del voucher"
          );
          break;
        case 'NumberOfProductsWithAllOptionValuesCondition':
        case 'NumberOfProductsWithAllOptionValuesCampaignProductCondition':
          errorMessage = this.i18n.gettext(
            "non sono rispettate le condizioni per l'applicazione del voucher"
          );
          break;
        case 'NumberOfPagesRangeCondition':
          errorMessage = this.i18n.gettext(
            "non sono rispettate le condizioni minime (di pagine) per l'applicazione del voucher"
          );
          break;
        case 'NumberOfConfirmedOrderCondition':
          errorMessage = this.i18n.gettext(
            "le condizioni per l'applicazione del voucher non sono corrette: il numero di ordini confermati non rispetta i requisiti"
          );
          break;
        case 'NumberOfUsesRangeCondition':
        case 'VoucherNumberOfUsesRangeCondition':
          errorMessage = this.i18n.gettext(
            'hai raggiunto il limite massimo di utilizzo del voucher'
          );
          break;
        case 'ProfessionalUserVatCondition':
        default:
          errorMessage = this.i18n.gettext('Non è stato possibile applicare il voucher');
      }

      errorMessage = errorMessage.replace(
        '{VOUCHER_URL}',
        `<a target="_blank" href="${conf.settings.RESERVED_AREA_VOUCHER_URL}">${this.i18n.gettext(
          'Voucher'
        )}</a>`
      );

      errorMessages.push(errorMessage);
    });

    return errorMessages;
  }

  render() {
    const { isOpen, errorTypes, voucher, language } = this.props;
    if (!isOpen) {
      return null;
    }

    const customClasses = {
      'modal-md': true,
      'modal-attention': true
    };
    const title = this.i18n.gettext('Attention');
    const couponFaqUrl = conf.settings.COUPON_FAQ_URLS[language];

    const errorMessages = this.getErrorMessages(errorTypes, voucher, language);

    const template = (
      <div className="custom-template">
        <h4 className="modal-title">{title}</h4>
        <hr />
        {errorMessages.length > 0 && (
          <div>
            {errorMessages.map((errorMessage, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: errorMessage }} />
            ))}
            {voucher.description && (
              <div style={{ marginTop: 30 }}>
                <p>{this.i18n.gettext('Termini e condizioni di utilizzo:')}</p>
                <p style={{ fontStyle: 'italic' }}>{voucher.description}</p>
              </div>
            )}
          </div>
        )}
        {errorMessages.length === 0 && (
          <p>{this.i18n.gettext('Non è stato possibile applicare il coupon: ') + voucher.code}</p>
        )}
        <hr />
        <p>
          <a onClick={this.openPromoFaq.bind(this, couponFaqUrl, voucher)}>
            {this.i18n.gettext("Why doesn't it work?")}
          </a>
        </p>
        <hr />
        <div className="modal-footer">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 text-center" />
            <div className="col-sm-6 col-md-6 col-lg-6 text-center">
              <a className="basic__cta__btns" onClick={this.close.bind(this)}>
                {this.i18n.gettext('chiudi')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AeModal
        isOpen={isOpen}
        title={title}
        customClasses={customClasses}
        customTemplate={template}
        onClose={this.close.bind(this)}
      />
    );
  }
}

CouponErrorAlert.propTypes = {
  isOpen: PropTypes.bool,
  errorTypes: PropTypes.array,
  voucher: PropTypes.object,
  language: PropTypes.string,
  onClose: PropTypes.func
};
