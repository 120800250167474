import React from 'react';
import classNames from 'classnames';

import { views, conf, routing } from 'outlinejs';

import { AeHeader, AeFooter, AeModal, AeLoader } from '@photosi/albumepoca-ui';

import Cookies from 'js-cookie';

import { BlockingAlertComponent } from './components/blockingAlertComponent';
import { OrderInWaitingPaymentAlertComponent } from './components/orderInWaitingPaymentAlertComponent';
import { AlertWithHideButton } from './components/alertWithHideButton';
import { PreviewModal } from './components/previewModal';
import { DuplicationAlert } from './components/duplicationAlert';
import { CouponErrorAlert } from './components/couponErrorAlert';
import { CouponWarningAlert } from './components/couponWarningAlert';

import { defaultCenter } from './notifications/notification-center';
import { GuiNotification } from './notifications';

import './styles/main.scss';
import EventTracker from './eventTracker';
import { CookieConsent } from './components/CookieConsent';

const COUPON_WARNING_ALERT_STORAGE_KEY = '__hideCouponWarningDialog';

/**
 * This is the main layout view.
 * It contains header, footer and main content that is overwritten by the current view.
 * */
export class EpocaBaseLayoutView extends views.BaseLayoutView {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      textModal: '',
      cancelLabelModal: null,
      errorDetailModal: null,
      confirmLabelModal: null,
      moreDetailLabel: this.i18n.gettext('dettagli'),
      titleModal: null,
      confirmCallbackModal: null,
      cancelCallbackModal: null,
      showCouponWarningDialog: false,
      couponWarningDialogCheckBox: null,
      couponWarningDialogMessage: null,
      showCouponErrorDialog: false,
      couponErrorTypes: null,
      couponErrorData: {},
      showDuplicationModal: false,
      duplicationCallback: null,
      showPreviewModal: false,
      imagePreviewUrl: null,
      svgPreview: null,
      enableImageDownload: null,
      enableSvgDownload: null,
      showAlertWithHideButton: false,
      showAlertWithHideButtonMessage: undefined,
      showAlertWithHideButtonTitle: undefined,
      showAlertWithHideButtonStorageKey: undefined
    };
  }

  get showHeader() {
    return true;
  }

  get showFooter() {
    return true;
  }

  get impersonationActive() {
    return !!Cookies.get(conf.settings.CUSTOMER_AUTH_COOKIE_KEY);
  }

  componentDidMount() {
    const errorEvent = GuiNotification.modalErrorEvent;
    const couponWarningDialog = GuiNotification.modalWarningCouponEvent;
    const couponErrorDialog = GuiNotification.modalErrorCouponEvent;
    const infoEvent = GuiNotification.modalInfoEvent;
    const confirmEvent = GuiNotification.modalConfirmEvent;
    const duplicationEvent = GuiNotification.modalDuplicationEvent;
    const previewEvent = GuiNotification.modalPreviewEvent;
    const { modalWithHideButtonEvent } = GuiNotification;

    defaultCenter.on(
      errorEvent,
      (errorText, errorDetailModal, cancelLabel, onConfirmCallback, onCancelCallback) => {
        const title = this.i18n.gettext('Attenzione');

        this.showModal(
          errorText,
          cancelLabel,
          errorDetailModal,
          null,
          title,
          onConfirmCallback,
          onCancelCallback
        );
      }
    );

    defaultCenter.on(couponWarningDialog, (content) => {
      this.showCouponWarningDialog(content);
    });

    defaultCenter.on(couponErrorDialog, (errorTypes, voucher) => {
      this.showCouponErrorDialog(errorTypes, voucher);
    });

    defaultCenter.on(
      infoEvent,
      (content, title, cancelLabel, onConfirmCallback, onCancelCallback) => {
        title = title || this.i18n.gettext('Attenzione');

        this.showModal(
          content,
          cancelLabel,
          null,
          null,
          title,
          onConfirmCallback,
          onCancelCallback
        );
      }
    );

    defaultCenter.on(
      confirmEvent,
      (content, confirmCallBack, cancelCallback, title, confirmLabelModal, cancelLabelModal) => {
        title = title || this.i18n.gettext('Attenzione');
        confirmLabelModal = confirmLabelModal || this.i18n.gettext('Conferma');
        cancelLabelModal = cancelLabelModal || this.i18n.gettext('Annulla');

        this.showModal(
          content,
          cancelLabelModal,
          null,
          confirmLabelModal,
          title,
          confirmCallBack,
          cancelCallback
        );
      }
    );

    defaultCenter.on(duplicationEvent, (confirmCallBack) => {
      this.setState({ duplicationCallback: confirmCallBack });
      this.showDuplicationDialog();
    });

    defaultCenter.on(previewEvent, (imagePreviewUrl, svgPreview, enableDownload = true) => {
      if (imagePreviewUrl) {
        this.setState({
          imagePreviewUrl,
          svgPreview: null,
          enableImageDownload: enableDownload
        });
      }
      if (svgPreview) {
        this.setState({
          svgPreview,
          imagePreviewUrl: null,
          enableSvgDownload: enableDownload
        });
      }
      this.showPreviewModal();
    });

    // modalWithHideButtonEvent
    defaultCenter.on(modalWithHideButtonEvent, (title, message, storageKey) => {
      // eslint-disable-line
      this.showAlertWithHideButtonModal(title, message, storageKey);
    });
  }

  async goToRegistering() {
    const registeringUrl = `${conf.settings.AE_WEBSITE_BASE_URL}${conf.settings.REGISTER_PATH}`;
    const nextUrlEncoded = encodeURIComponent(this.request.absoluteUrl);
    const registeringUrlWithNextUrl = `${registeringUrl}?next=${nextUrlEncoded}`;
    this.response.navigate(registeringUrlWithNextUrl);
  }

  async goToNoProfessional() {
    const noProfessionalUrl = `${conf.settings.AE_WEBSITE_BASE_URL}${this.request.language}/${conf.settings.NO_PROFESSIONAL_PATH}`;
    this.response.navigate(noProfessionalUrl);
  }

  showModal(
    text,
    cancelLabelModal = null,
    errorDetailModal = null,
    confirmLabelModal = null,
    titleModal = null,
    onConfirmCallbackModal = null,
    onCancelCallbackModal = null
  ) {
    if (onConfirmCallbackModal == null && cancelLabelModal == null) {
      cancelLabelModal = this.i18n.gettext('chiudi');
    }

    async function cancelCallbackModal() {
      if (onCancelCallbackModal) {
        onCancelCallbackModal();
      }
      this.clearModalParams();
    }

    async function confirmCallbackModal() {
      if (onConfirmCallbackModal) {
        onConfirmCallbackModal();
      }
      this.clearModalParams();
    }

    this.setState({
      showModal: true,
      textModal: text,
      cancelLabelModal,
      errorDetailModal,
      confirmLabelModal,
      titleModal,
      confirmCallbackModal: onConfirmCallbackModal ? confirmCallbackModal.bind(this) : null,
      cancelCallbackModal: cancelCallbackModal.bind(this)
    });
  }

  /**
   * clear all the modal param for avoiding junk variables
   * the modal actual closing is handled by the AeModal react component itself
   */
  async clearModalParams() {
    await this.setState({
      showModal: false,
      textModal: null,
      cancelLabelModal: null,
      errorDetailModal: null,
      confirmLabelModal: null,
      titleModal: null,
      confirmCallbackModal: null,
      cancelCallbackModal: null
    });
  }

  showCouponWarningDialog(content) {
    const couponWarningDialog = localStorage.getItem(COUPON_WARNING_ALERT_STORAGE_KEY);
    if (!couponWarningDialog) {
      this.setState({
        showCouponWarningDialog: true,
        couponWarningDialogMessage: content
      });
    }
  }

  closeCouponWarningDialog() {
    this.setState({
      showCouponWarningDialog: false,
      couponWarningDialogMessage: null
    });
  }

  showCouponErrorDialog(errorTypes, voucher) {
    this.setState({
      showCouponErrorDialog: true,
      couponErrorTypes: errorTypes,
      couponErrorData: voucher
    });
  }

  closeCouponErrorDialog() {
    this.setState({
      showCouponErrorDialog: false,
      couponErrorTypes: null,
      couponErrorData: {}
    });
  }

  showDuplicationDialog() {
    this.setState({
      showDuplicationModal: true
    });
  }

  async confirmDuplicationModal() {
    this.closeDuplicationModal();
    this.state.duplicationCallback();
  }

  closeDuplicationModal() {
    this.setState({
      showDuplicationModal: false
    });
  }

  showPreviewModal() {
    this.setState({
      showPreviewModal: true
    });
  }

  closePreviewModal() {
    this.setState({
      showPreviewModal: false
    });
  }

  showAlertWithHideButtonModal(title, message, storageKey) {
    if (localStorage.getItem(storageKey)) {
      return;
    }

    this.setState({
      showAlertWithHideButton: true,
      showAlertWithHideButtonMessage: message,
      showAlertWithHideButtonTitle: title,
      showAlertWithHideButtonStorageKey: storageKey
    });
  }

  hideAlertWithHideButtonModal() {
    this.setState({
      title: undefined,
      message: undefined,
      storageKey: undefined,
      showAlertWithHideButton: false
    });
  }

  render() {
    const {
      showModal,
      textModal,
      cancelLabelModal,
      errorDetailModal,
      moreDetailLabel,
      confirmLabelModal,
      titleModal,
      confirmCallbackModal,
      cancelCallbackModal,
      showPreviewModal,
      imagePreviewUrl,
      svgPreview,
      enableImageDownload,
      enableSvgDownload,
      showDuplicationModal,
      showCouponErrorDialog,
      couponErrorTypes,
      couponErrorData,
      showCouponWarningDialog,
      couponWarningDialogMessage,
      showAlertWithHideButton,
      showAlertWithHideButtonMessage,
      showAlertWithHideButtonTitle,
      showAlertWithHideButtonStorageKey
    } = this.state;

    const languages = conf.settings.REFERRAL_AVAILABLE_LANGUAGES;

    const { totalCartProjectsQuantity, totalBucketProjectsQuantity } = this.props.contentProps;

    const mainContentClass = classNames('mainContent', {
      'full-screen': this.showHeader === false
    });

    const nextUrlEncoded = encodeURIComponent(this.request.absoluteUrl);
    const logoutUrlWithNextUrl = `${routing.Utils.reverse('logout')}?next-url=${nextUrlEncoded}`;
    const loginUrlWithNextUrl = `${routing.Utils.reverse('login')}?next-url=${nextUrlEncoded}`;
    // Flag for top menu visualization
    const topMenu = this.props.contentProps.topMenuBarVisible;
    const homepageUrl = `${conf.settings.HOMEPAGE_BASE_URL}${this.request.language}/`;

    return (
      <div>
        <CookieConsent />
        {this.showHeader ? (
          <AeHeader
            headerMinimal={topMenu}
            lang={this.request.language}
            user={this.props.contentProps.user}
            logoutUrl={logoutUrlWithNextUrl}
            loginUrl={loginUrlWithNextUrl}
            goToRegistering={this.goToRegistering.bind(this)}
            goToNoProfessional={this.goToNoProfessional.bind(this)}
            cartUrl="/"
            cartItemsTotal={totalCartProjectsQuantity}
            bucketUrl={`/#bucket`}
            bucketItemsTotal={totalBucketProjectsQuantity}
            homepageUrl={homepageUrl}
            adminAreaUrl={`${conf.settings.MYPHOTOSI_ORDERS_URL}`}
            impersonationActive={this.impersonationActive}
          />
        ) : null}
        <div className={mainContentClass}>
          {this.renderContent()}
          {this.showFooter ? (
            <AeFooter
              lang={this.request.language}
              languages={languages}
              activeLanguage={this.request.language}
            />
          ) : null}
          <AeModal
            isOpen={showModal}
            text={textModal}
            title={titleModal}
            moreDetail={errorDetailModal}
            moreDetailLabel={moreDetailLabel}
            confirmLabel={confirmLabelModal}
            dismissLabel={cancelLabelModal}
            onConfirm={confirmCallbackModal}
            onDismiss={cancelCallbackModal}
          />
          <CouponWarningAlert
            isOpen={showCouponWarningDialog}
            storageKey={COUPON_WARNING_ALERT_STORAGE_KEY}
            message={couponWarningDialogMessage}
            onClose={this.closeCouponWarningDialog.bind(this)}
          />
          <CouponErrorAlert
            isOpen={showCouponErrorDialog}
            language={this.request.language}
            errorTypes={couponErrorTypes}
            voucher={couponErrorData}
            onClose={this.closeCouponErrorDialog.bind(this)}
          />
          <DuplicationAlert
            isOpen={showDuplicationModal}
            language={this.request.language}
            onConfirm={this.confirmDuplicationModal.bind(this)}
            onClose={this.closeDuplicationModal.bind(this)}
          />
          <PreviewModal
            isOpen={showPreviewModal}
            imagePreviewUrl={imagePreviewUrl}
            svgPreview={svgPreview}
            enableImageDownload={enableImageDownload}
            enableSvgDownload={enableSvgDownload}
            onClose={this.closePreviewModal.bind(this)}
          />
          <AlertWithHideButton
            isOpen={showAlertWithHideButton}
            message={showAlertWithHideButtonMessage}
            title={showAlertWithHideButtonTitle}
            storageKey={showAlertWithHideButtonStorageKey}
            onClose={this.hideAlertWithHideButtonModal.bind(this)}
          />
        </div>
      </div>
    );
  }
}

/**
 * This is the content view used to render page content.
 *
 * NOTE:
 * in your application view extends this class and override the method renderContent().
 * If the property initViewRendering is defined, it shows a loading spinner until the main content is not ready.
 * */
export class EpocaBaseContentView extends views.BaseView {
  renderContent() {
    return <div>This is the main content view</div>;
  }

  blockedCLass() {
    return classNames('row', {
      activeLoader: this.props.initViewRendering || this.props.contentIsLoading
    });
  }

  getBlockingMessageData() {
    const data = {};
    data.title = this.i18n.gettext('Attenzione');
    data.blockingClass = 'blocking-dialog';
    data.blockingDialogDismissLabel = this.i18n.gettext('ho capito');
    return data;
  }

  getOrderInWaitingPaymentMessageData() {
    const data = {};
    data.title = this.i18n.gettext('Ordine in attesa di pagamento');
    data.blockingClass = 'blocking-dialog';
    data.orderInWaitingPaymentMessageResetOrderLabel = this.i18n.gettext('annulla');
    data.orderInWaitingPaymentMessageDialogCheckoutOrderLabel = this.i18n.gettext('paga ora');
    return data;
  }

  onCheckoutOrderClick() {
    EventTracker.log(this.customerUser, 'payment_retry', {
      online_payment: true
    });

    this.props.delegate.navigateToPaymentView(this.props.cartHeader.model.id);
  }

  async onResetOrderClick() {
    EventTracker.log(this.customerUser, 'payment_cancelled', {
      online_payment: true
    });

    await this.props.delegate.cancelPayment(this.props.cartHeader.model.id);
  }

  render() {
    return (
      <div className="container">
        <AeLoader active={this.props.initViewRendering || this.props.contentIsLoading} />
        <BlockingAlertComponent
          isActive={this.props.delegate.blockingPopupIsActive}
          isOpen={this.props.delegate.onEnterPopupIsActive}
          title={this.getBlockingMessageData().title}
          blockingClass={this.getBlockingMessageData().blockingClass}
          dismissLabel={this.getBlockingMessageData().blockingDialogDismissLabel}
          onDismiss={null}
        />
        <OrderInWaitingPaymentAlertComponent
          isOpen={this.props.delegate.orderInWaitingPaymentAlertIsActive}
          title={this.getOrderInWaitingPaymentMessageData().title}
          blockingClass={this.getOrderInWaitingPaymentMessageData().blockingClass}
          resetOrderLabel={
            this.getOrderInWaitingPaymentMessageData().orderInWaitingPaymentMessageResetOrderLabel
          }
          checkoutOrderLabel={
            this.getOrderInWaitingPaymentMessageData()
              .orderInWaitingPaymentMessageDialogCheckoutOrderLabel
          }
          onCheckoutOrderClick={this.onCheckoutOrderClick.bind(this)}
          onResetOrderClick={this.onResetOrderClick.bind(this)}
        />
        <div className={this.blockedCLass()}>
          <div className="col-lg-12" style={{ minHeight: 700 }}>
            {this.props.initViewRendering ? null : this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}
