import classNames from 'classnames';
import React from 'react';
import { components } from 'outlinejs';
import PropTypes from 'prop-types';

const ORDER_SUMMARY_EMPTY_VALUE = '-';

/**
 * Coupon Component.
 */
class CouponForm extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      couponValue: props.couponCode,
      projectsNumber: props.projectsNumber
    };
    this.handleChangeCoupon = this.handleChangeCoupon.bind(this);
    this.addCoupon = this.addCoupon.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      couponValue: nextProps.couponCode,
      projectsNumber: nextProps.projectsNumber
    });
  }

  handleChangeCoupon(event) {
    this.setState({ couponValue: event.target.value });
  }

  async addCoupon() {
    if (this.state.couponValue && this.state.projectsNumber > 0) {
      this.props.onCouponAdd(this.state.couponValue);
    }
  }

  removeCoupon() {
    return this.props.onCouponRemove(this.state.couponValue);
  }

  renderAddButtonElement() {
    return (
      <button className="btn btn-primary" type="button" onClick={this.addCoupon}>
        {this.i18n.gettext('Applica')}
      </button>
    );
  }

  renderRemoveButtonElement() {
    return (
      <button className="btn btn-primary" type="button" onClick={this.removeCoupon}>
        {this.i18n.gettext('Rimuovi')}
      </button>
    );
  }

  render() {
    const input = (
      <input
        type="text"
        className="form-control font-xs ae-input"
        placeholder={this.i18n.gettext('Inserisci codice sconto')}
        value={this.state.couponValue || ''}
        onChange={this.handleChangeCoupon}
        readOnly={this.props.couponCode !== null}
      />
    );
    const couponButtonElement = this.props.couponCode
      ? this.renderRemoveButtonElement()
      : this.renderAddButtonElement();
    return (
      <div className="row order_summary_coupons">
        <div className="col-xs-12">
          <div className="input-group">
            {input}
            <div className="fa-stack icontag green">
              <i className="fa fa-tag fa-stack-2x fa-flip-horizontal" />
              <i className="fa fa-percent my-fa-rotate-320 my-fa-80 fa-inverse fa-stack-1x" />
            </div>
            <span className="input-group-btn">{couponButtonElement}</span>
          </div>
        </div>
      </div>
    );
  }
}

CouponForm.propTypes = {
  /**
   * Coupon code to visualize. if not null remove callback is present
   */
  couponCode: PropTypes.string
};

CouponForm.defaultProps = {
  couponCode: null
};
/**
 * Order summary row with label and value.
 */
const OrderSummaryRow = (props) => {
  const summaryRowClass = classNames('row', 'no-bs-margin', 'order_summary_row', {
    orderSummaryRowBottomline: props.bottomline
  });
  return (
    <div className={summaryRowClass}>
      <span className="col-xs-6 no-bs-padding order_summary_label">{props.label}</span>
      <span className="col-xs-6 no-bs-padding order_summary_value text-right">{props.value}</span>
    </div>
  );
};

OrderSummaryRow.propTypes = {
  bottomline: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string
};

/**
 * OrderSummary Component.
 */
export class OrderSummary extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.onMainButtonClick = this.onMainButtonClick.bind(this);
    this.onAddProductClick = this.onAddProductClick.bind(this);
  }

  onAddProductClick() {
    this.props.onAddProductClick();
  }

  onMainButtonClick() {
    this.props.onMainButtonClick();
  }

  renderCoupons() {
    const { onCouponRemove } = this.props;
    const { onCouponAdd } = this.props;
    const projectsNumber = this.props.numberOfProducts;
    const applied = this.props.coupons
      ? this.props.coupons.map((coupon, index) => (
          <CouponForm
            key={index}
            projectsNumber={projectsNumber}
            couponCode={coupon}
            onCouponAdd={onCouponAdd}
            onCouponRemove={onCouponRemove}
          />
        ))
      : null;

    let toapply = null;
    if (
      this.props.coupons &&
      this.props.coupons.length < this.props.couponsLimit &&
      this.props.couponValid
    ) {
      toapply = (
        <CouponForm
          couponCode={null}
          onCouponAdd={onCouponAdd}
          onCouponRemove={onCouponRemove}
          projectsNumber={projectsNumber}
        />
      );
    }
    return (
      <div className="row">
        <div className="col-xs-12">
          {applied}
          {toapply}
        </div>
      </div>
    );
  }

  render() {
    const {
      subTotalWithoutVoucherValue,
      voucherValue,
      subTotal,
      delivery,
      vat,
      price,
      isLoading,
      hideAddProducts,
      hasDiscount
    } = this.props;

    const mainContentClassName = classNames('order_summary_content', {
      'component-is-loading': isLoading
    });

    const mainButtonLabel = this.props.mainButtonLabel || this.i18n.gettext('Vai alla cassa');

    let addProductsButton = null;
    if (!hideAddProducts) {
      addProductsButton = (
        <div className="row order_summary_add_action">
          <div className="col-xs-12">
            <button className="btn cta-primary-inverse btn-block" onClick={this.onAddProductClick}>
              {this.i18n.gettext('Aggiungi prodotti')}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className={mainContentClassName}>
        <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
          <div className="row">
            <div className="col-xs-12 col-xs-offset-0 col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0 order-summary-section">
              <h4 className="order_summary_title text-uppercase">
                {this.i18n.gettext('Il tuo ordine')}
              </h4>
              {hasDiscount ? (
                <OrderSummaryRow
                  label={this.i18n.gettext('Totale prodotti')}
                  value={subTotalWithoutVoucherValue}
                />
              ) : null}
              {hasDiscount ? (
                <OrderSummaryRow
                  label={this.i18n.gettext('Totale sconto')}
                  value={`- ${voucherValue}`}
                />
              ) : null}
              {hasDiscount ? <OrderSummaryRow label="" bottomline={true} /> : null}
              <OrderSummaryRow label={this.i18n.gettext('Totale netto')} value={subTotal} />
              {delivery ? (
                <OrderSummaryRow label={this.i18n.gettext('Spedizione')} value={delivery} />
              ) : null}
              <OrderSummaryRow label={this.i18n.gettext('IVA')} value={vat} />
              <OrderSummaryRow label="" bottomline={true} />
              <div className="row no-bs-margin order_summary_row">
                <span className="col-xs-6 no-bs-padding order_summary_label">
                  {this.i18n.gettext('Totale ordine')}
                </span>
                <div className="total_prices text-right">
                  <PriceSet price={price} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12 padding-order-summary-section">
          <div className="row">
            <div className="col-xs-12 col-xs-offset-0 col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0 order-summary-section">
              {this.renderCoupons()}

              <div className="row order_summary_main_action">
                <div className="col-xs-12">
                  <button
                    className="btn btn-primary btn-block btn-lg"
                    onClick={this.onMainButtonClick}>
                    <span>{mainButtonLabel}</span>
                  </button>
                </div>
              </div>
              {addProductsButton}
            </div>
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

OrderSummary.propTypes = {
  isLoading: PropTypes.bool,
  /**
   * Number of products in cart
   */
  numberOfProducts: PropTypes.number,
  /**
   * Order sub total cost
   */
  subTotal: PropTypes.string,
  /**
   * Order IVA cost
   */
  iva: PropTypes.string,
  /**
   * Delivery cost
   */
  delivery: PropTypes.string,
  /**
   * Order total price
   */
  price: PropTypes.string,
  /**
   * Order total discounted price
   */
  fullPrice: PropTypes.string,
  /**
   * Coupons applied to order
   */
  coupons: PropTypes.array,
  couponsLimit: PropTypes.number,
  /**
   * Main action callback
   * */
  onMainButtonClick: PropTypes.func.isRequired,
  /**
   * Add products callback
   * */
  onAddProductClick: PropTypes.func.isRequired,
  /**
   * Main button label
   * */
  mainButtonLabel: PropTypes.string,
  /**
   * Coupon validaion
   * */
  couponValid: PropTypes.bool
};

OrderSummary.defaultProps = {
  couponValid: true,
  isLoading: false,
  numberOfProducts: 0,
  subTotal: ORDER_SUMMARY_EMPTY_VALUE,
  iva: ORDER_SUMMARY_EMPTY_VALUE,
  total: ORDER_SUMMARY_EMPTY_VALUE,
  coupons: [],
  couponsLimit: 0
};

export class PriceSet extends components.BaseComponent {
  render() {
    // show full price only if prices are different
    const fullPriceElement =
      this.props.fullprice === this.props.price ? (
        ''
      ) : (
        <del className="color-primary">
          <span className="full-price">{this.props.fullprice}</span>
        </del>
      );

    return (
      <div>
        <div className="price">{this.props.price}</div>
        {fullPriceElement}
      </div>
    );
  }
}
