import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { components } from 'outlinejs';

const MODAL_STYLE = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.45)',
    zIndex: '1050' // default bootstrap modal z-index value
  }
};

export class OrderInWaitingPaymentAlertComponent extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.onResetOrderClick = this.onResetOrderClick.bind(this);
    this.onCheckoutOrderClick = this.onCheckoutOrderClick.bind(this);
  }

  onCheckoutOrderClick() {
    this.props.onCheckoutOrderClick();
  }

  onResetOrderClick() {
    this.props.onResetOrderClick();
  }

  getOrderInWaitingPaymentMessage() {
    const msg = (
      <div>
        {this.i18n.gettext(
          'Clicca su "paga ora" per procedere con il pagamento oppure su "annulla" per tornare al carrello senza perdere il progetto.'
        )}
      </div>
    );
    return msg;
  }

  render() {
    const { title, blockingClass, resetOrderLabel, checkoutOrderLabel } = this.props;
    const resetOrderButton = (
      <button className="btn btn-default btn-block" onClick={this.onResetOrderClick.bind(this)}>
        {resetOrderLabel}
      </button>
    );

    const checkoutOrderButton = (
      <button className="btn btn-primary btn-block" onClick={this.onCheckoutOrderClick.bind(this)}>
        {checkoutOrderLabel}
      </button>
    );

    const content = this.getOrderInWaitingPaymentMessage();

    const modalBody = (
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{title}</h4>
        </div>
        <div className="modal-body">{content}</div>
        <div className="modal-footer">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 text-center">{resetOrderButton}</div>
            <div className="col-sm-6 col-md-6 col-lg-6 text-center">{checkoutOrderButton}</div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <Modal
          className={`Modal__Bootstrap modal-dialog ${blockingClass}`}
          isOpen={this.props.isOpen}
          bodyOpenClassName="null"
          style={MODAL_STYLE}
          ariaHideApp={false}>
          {modalBody}
        </Modal>
      </div>
    );
  }
}

OrderInWaitingPaymentAlertComponent.propTypes = {
  /**
   * Html template for modal
   */
  // content: PropTypes.string,
  /**
   * Title
   */
  title: PropTypes.string,
  /**
   * Custom modal class
   */
  blockingClass: PropTypes.string,
  /**
   * Dismiss label
   */
  dismissLabel: PropTypes.string,
  /**
   * onCheckoutOrderClick function
   */
  onCheckoutOrderClick: PropTypes.func.isRequired,
  /**
   * onResetOrderClick function
   */
  onResetOrderClick: PropTypes.func.isRequired
};
