import { components } from 'outlinejs';
import React from 'react';

/**
 * Quantity input component.
 */
export class QuantityInput extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      quantity: props.quantity
    };
  }

  increase() {
    const val = this.state.quantity + 1;
    this.setState({ quantity: val });
    this.props.onQuantityChange(val);
  }

  decrease() {
    if (this.state.quantity !== 1) {
      const val = this.state.quantity - 1;
      this.setState({ quantity: val });
      this.props.onQuantityChange(val);
    }
  }

  async handleChange(event) {
    let val = Math.round(event.target.value);
    if (val < 1) {
      val = 1;
    }
    if (!this.props.onQuantityChangeInProgress) {
      this.setState({ quantity: val });
      await this.props.onQuantityChange(val);
    }
  }

  handleNotvalids() {
    if (this.state.quantity < 1) {
      this.setState({ quantity: 1 });
      this.props.onQuantityChange(1);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      quantity: nextProps.quantity
    });
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.quantity !== this.props.quantity;
  }

  render() {
    return (
      <div className="input-group input-group-quantity">
        <span className="input-group-btn">
          <button className="btn btn-ae-icon" onClick={this.decrease.bind(this)}>
            -
          </button>
        </span>
        <input
          type="number"
          className="form-control ae-input text-center"
          value={this.state.quantity}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleNotvalids.bind(this)}
        />
        <span className="input-group-btn">
          <button className="btn btn-ae-icon" onClick={this.increase.bind(this)}>
            +
          </button>
        </span>
      </div>
    );
  }
}
