import { runtime } from '@outlinejs/contexts';
import { conf, controllers } from 'outlinejs';

import Cookies from 'js-cookie';

import { ProfessionalCartHeader, ProfessionalOrderHeader } from '../orders/models';

import { navigateTo, promiseRetry } from './utils';
import { GuiNotification } from './notifications';
import Logger from './logger';
import { EpocaBaseLayoutView, EpocaBaseContentView } from './views';
import { CartProjectCollection } from '../projects/managers';
import { Voucher } from '../vouchers/models';
import EventTracker from './eventTracker';
import { CustomError } from './errors';
import OAuth2 from '../auth/oauth';
import axios from 'axios';

export class EpocaBaseController extends controllers.BaseLayoutController {
  static get loginRequired() {
    return runtime.isClient;
  }

  get layoutView() {
    return EpocaBaseLayoutView;
  }

  get view() {
    return EpocaBaseContentView;
  }

  get impersonationActive() {
    return !!Cookies.get(conf.settings.CUSTOMER_AUTH_COOKIE_KEY);
  }

  isBetaTesterUser() {
    const shopCodes = conf.settings.ENABLED_SHOPCODES;
    if (this.request && this.request.customerUser) {
      return shopCodes.some((x) => x === this.request.customerUser.shopCode);
    }
    return false;
  }

  isBlockingMessageActive() {
    if (this.isBetaTesterUser()) {
      this.blockingPopupIsActive = false;
      return false;
    }
    this.blockingPopupIsActive = conf.settings.MAINTENANCE_MESSAGE;
  }

  onEnterMessageModalIsActive() {
    this.onEnterPopupIsActive = conf.settings.ALERT_ON_LOGIN;
  }

  hasCartOnlinePayment(cartModel) {
    return cartModel.hasOnlinePayment;
  }

  get context() {
    return {
      // used to set a loader inside the view to define when the first rendering must be executed
      contentIsLoading: this.contentIsLoading,
      initViewRendering: this.initViewRendering,
      customerUser: this.customerUser,
      user: this.user,
      topMenuBarVisible: this.topMenuBarVisible,
      blockingPopupIsActive: this.blockingPopupIsActive,
      onEnterPopupIsActive: this.onEnterPopupIsActive,
      totalCartProjectsQuantity: this.totalCartProjectsQuantity,
      totalBucketProjectsQuantity: this.totalBucketProjectsQuantity,
      cartHeader: this.cartHeader,
      orderHeader: this.orderHeader
    };
  }

  /*
   * Initialize view properties
   * */
  async initContentProps() {
    this.initViewRendering = false;
    this.contentIsLoading = false;

    this.user = null;
    this.customerUser = null;
    this.topMenuBarVisible = true;

    this.blockingPopupIsActive = false;
    this.onEnterPopupIsActive = false;

    this.cartHeader = {
      model: new ProfessionalCartHeader(),
      isLoading: false,
      validCoupon: true,
      cartIsEnabled: false
    };

    this.orderHeader = {
      model: new ProfessionalOrderHeader(),
      isLoading: false,
      validCoupon: true
    };

    if (runtime.isClient && this.request) {
      this.customerUser = this.request.customerUser;
      this.user = this.request.user;
    }
  }

  /*
   * start initial view rendering and replace content with loader
   * */
  startInitialRendering() {
    this.initViewRendering = true;
    this.render(this.context);
  }

  /*
   * stop initial rendering
   * */
  stopInitialRendering() {
    this.initViewRendering = false;
    this.render(this.context);
  }

  /*
   * sets the status 'loading' to the entire view
   * */
  startLoadingPage() {
    this.contentIsLoading = true;
    this.render(this.context);
  }

  /*
   * remove the status 'loading' to the entire view
   * */
  stopLoadingPage() {
    this.contentIsLoading = false;
    this.render(this.context);
  }

  navigateToCartView() {
    this.response.navigate('cart:main');
  }

  navigateToCheckoutView() {
    this.response.navigate('checkout:main');
  }

  navigateToPaymentView(cartGuid) {
    this.response.navigate('payment:main', { cartGuid });
  }

  navigateToOrderConfirmationView(orderGuid) {
    navigateTo(
      this.request,
      this.response,
      'order-confirmation:orderGuid',
      {
        orderGuid
      },
      true
    );
  }

  /* Cart Header methods */
  async loadCartHeader(includeDeliveryTimes = false, loaderEnabled = true) {
    if (loaderEnabled) {
      this.cartHeader.isLoading = true;
      this.render(this.context);
    }

    const { shopCode } = this.customerUser;
    try {
      if (includeDeliveryTimes) {
        await this.cartHeader.model.fetchWithDeliveryTimes({
          data: { shopCode }
        });
      } else {
        await this.cartHeader.model.fetch({ data: { shopCode } });
      }
    } catch (error) {
      Logger.error('CartCheckoutBaseController.loadCartHeader', {
        error,
        context: this.context,
        orderHeader: this.cartHeader
      });
    }

    if (loaderEnabled) {
      this.cartHeader.isLoading = false;
      this.cartHeader.cartIsEnabled = this.cartHeader.model.cartIsEnabled;
      this.render(this.context);
    }
  }

  async loadCurrentOrderHeader(cartGuid) {
    const { shopCode } = this.customerUser;

    if (!this.orderHeader.model) {
      this.orderHeader.model = new ProfessionalOrderHeader();
    }

    try {
      this.orderHeader.isLoading = true;
      this.orderHeader.model.current();

      this.orderHeader.model = await this.orderHeader.model.fetch({
        data: { shopCode, cartGuid }
      });
    } catch (err) {
      // se non ho l orderHeader significa che il carrello non è stato convertito in ordine e quindi si possono aggiungere ordini
      if (err.code === 404) {
        this.orderHeader.model = null;
      } else {
        Logger.error('loadCurrentOrderHeader', {
          error: err,
          shopCode,
          orderHeader: this.orderHeader
        });
        this.orderHeader.model = null;
      }
    }

    this.orderHeader.isLoading = false;

    return this.orderHeader.model;
  }
}

/**
 * Common controller inherited by /cart and /checkout views.
 * User must be authenticated to view content.
 * */
export class CartCheckoutBaseController extends EpocaBaseController {
  get context() {
    return Object.assign(super.context, {
      cartProjects: this.cartProjects, // collection dei projects
      preOrders: this.preOrders, // struttura dei preorder
      loadingCollection: this.loadingCollection,
      changeQuantityInProgress: this.changeQuantityInProgress,
      orderInWaitingPaymentAlertIsActive: this.orderInWaitingPaymentAlertIsActive
    });
  }

  async initContentProps() {
    await super.initContentProps();

    this.cartProjects = {
      collection: new CartProjectCollection(),
      isLoading: false
    };

    this.preOrders = {};

    this.loadingCollection = [];

    this.changeQuantityInProgress = false;

    this.orderInWaitingPaymentAlertIsActive = false;

    this.getProjectsQuantity();
  }

  /* Projects methods */
  async fetchPreOrderCollection(project, includePreOrderDescription = false) {
    return await project.fetchPreorderCollection(includePreOrderDescription);
  }

  async fetchPreOrderCollectionWithRetry(project, includePreOrderDescription = false) {
    return await promiseRetry(
      this.fetchPreOrderCollection.bind(this, project, includePreOrderDescription),
      'Retry to fetch PreOrderCollection',
      { project }
    );
  }

  async updateCartProjectsPreorder() {
    const promises = this.cartProjects.collection.map(async (project) => {
      try {
        await this.fetchPreOrderCollectionWithRetry(project, true);
      } catch (err) {
        const timestamp = new Date().getTime();
        Logger.error('CartCheckoutBaseController.updateCartProjectsPreorder', {
          error: err,
          errorCode: timestamp,
          project: project.id
        });
      } finally {
        this.render(this.context);
      }
    });
    await Promise.all(promises);
  }

  async getProjectsQuantity() {
    this.getAllProjectsQuantity();
  }

  async getProjectsQuantityByState(state) {
    try {
      const shopCode = this.customerUser.shopCode;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': conf.settings.X_API_KEY,
          Authorization: OAuth2.getBearerToken()
        }
      };

      let requestUrl = `${conf.settings.PROJECTS_QUANTITY_URL}?shopCode=${shopCode}&pageSize=0&softwareCode=PROFESSIONAL&currentPage=1`;
      if (state) {
        requestUrl += `&state=${state}`;
      }
      const response = await axios.get(requestUrl, options);
      return JSON.parse(response.headers['x-pagination'])['itemsCount'];
    } catch (error) {
      console.error(`failed getProjectsQuantityByState for state: ${state}`, error);
      return null;
    }
  }

  async getAllProjectsQuantity() {
    [this.totalCartProjectsQuantity, this.totalBucketProjectsQuantity] = await Promise.all([
      this.getProjectsQuantityByState('Cart'),
      this.getProjectsQuantityByState('Bucket')
    ]);
    this.render(this.context);
  }

  async fetchCartProjects(shopCode, cartGuid) {
    return await this.cartProjects.collection.fetchCartProjects(shopCode, cartGuid);
  }

  async fetchCartProjectsWithRetry(shopCode, cartGuid) {
    return await promiseRetry(
      this.fetchCartProjects.bind(this, shopCode, cartGuid),
      'Retry to fetch cart projects',
      { shopCode }
    );
  }

  async getCartProjects() {
    this.cartProjects.isLoading = true;
    try {
      const shopcode = this.customerUser.shopCode;
      const cartGuid = this.cartHeader.model.id;
      await this.fetchCartProjectsWithRetry(shopcode, cartGuid);

      this.render(this.context);
      await this.updateCartProjectsPreorder();
    } catch (error) {
      Logger.error('CartCheckoutBaseController.getCartProjects', {
        error,
        context: this.context
      });
    } finally {
      this.cartProjects.isLoading = false;
      this.render(this.context);
    }
  }

  hasPendingPayment(cartHeaderModel) {
    return ['new'].indexOf(cartHeaderModel.cartState.toLowerCase()) === -1;
  }

  async addCoupon(coupon, eventName = 'promo_code_apply') {
    this.startLoadingPage();

    const voucher = new Voucher({ code: coupon });

    let eventNameSuccess = 'promo_code_success';
    let eventNameError = 'promo_code_error';
    if (this.cartHeader.model.couponCodes.length === 1) {
      eventNameSuccess = 'second_promo_code_success';
      eventNameError = 'second_promo_code_error';
    }

    try {
      await voucher.checkValidity(coupon, this.customerUser.shopCode);
    } catch (err) {
      const promo_code_id = voucher.campaignCode || 'NOT_EXISTENT_PROMO';

      EventTracker.log(this.customerUser, eventName, {
        promo_code_id
      });

      GuiNotification.modalErrorCoupon([err.name], voucher);

      EventTracker.log(this.customerUser, eventNameError, {
        promo_code_id,
        error_code: err.message
      });

      this.stopLoadingPage();
      return false;
    }

    let warningMessage = null;

    this.cartProjects.isLoading = true;
    this.cartHeader.validCoupon = false;
    const coupons = this.cartHeader.model.couponCodes;
    coupons.push(voucher.code);
    this.cartHeader.model.couponCodes = coupons;
    this.render(this.context);

    EventTracker.log(this.customerUser, eventName, {
      promo_code_id: voucher.campaignCode
    });

    try {
      await this.cartHeader.model.save();
      warningMessage = this.cartHeader.model.warningMessage;
      this.getCartProjects();

      EventTracker.log(this.customerUser, eventNameSuccess, {
        promo_code_id: voucher.campaignCode
      });
    } catch (err) {
      const error = new CustomError(err);

      let errors = [];
      let eventProps = { promo_code_id: voucher.campaignCode, error_code: error.name };

      if (err.code === 400 && err.errorJSON && err.errorJSON.errors) {
        errors = err.errorJSON.errors;
        eventProps.error_code = err.errorJSON.errors[0];
      }

      GuiNotification.modalErrorCoupon(errors, voucher);

      EventTracker.log(this.customerUser, eventNameError, eventProps);

      if (errors.length > 1) {
        Logger.error('Multiple promo code errors', { errorList: errors });
      }

      this.cartProjects.isLoading = false;
      this.render(this.context);
    } finally {
      this.cartHeader.validCoupon = true;
      this.loadCartHeader();
      this.stopLoadingPage();

      if (warningMessage) {
        GuiNotification.modalWarningCoupon(warningMessage);
      }
    }
  }

  async removeCoupon(coupon) {
    this.startLoadingPage();

    const voucher = new Voucher({ code: coupon });

    // we need to get voucher campaignCode to ensure data consistency on amplitude
    let promo_code_id = 'GENERAL_VOUCHER';
    try {
      await voucher.fetch();
      promo_code_id = voucher.campaignCode;
    } catch (err) {
      // skip error because this API is needed only for amplitude
    }

    EventTracker.log(this.customerUser, 'promo_code_remove', {
      promo_code_id
    });

    const coupons = [];
    for (const element of this.cartHeader.model.couponCodes) {
      if (coupon !== element) {
        coupons.push(element);
      }
    }
    this.cartHeader.model.couponCodes = coupons;
    this.render(this.context);

    try {
      await this.cartHeader.model.save();
      this.getCartProjects();
    } catch (err) {
      const timestamp = new Date().getTime();
      Logger.error('CartCheckoutBaseController.removeCoupon', {
        error: err,
        errorCode: timestamp,
        coupon
      });
      GuiNotification.modalError(
        this.i18n.gettext('Non è stato possibile rimuovere il coupon: ') + coupon,
        `Error detail: CartController.removeCoupon - code: ${timestamp}`
      );

      await this.cartHeader.model.fetch();
    } finally {
      this.loadCartHeader();
      this.stopLoadingPage();
    }
  }

  cartProjectComponentIsLoading(id) {
    return this.loadingCollection.indexOf(id) !== -1;
  }

  addProjectToLoadingCollection(id) {
    this.loadingCollection.push(id);
  }

  removeProjectFromLoadingCollection(id) {
    const pos = this.loadingCollection.indexOf(id);
    if (pos !== -1) {
      this.loadingCollection.splice(pos, 1);
    }
    this.changeQuantityInProgress = false;
    this.render(this.context);
  }

  async redirectToChoseProjectPage(projectId) {
    const url = `${conf.settings.AE_CONFIGURATOR_BASE_URL}duplicate-project/duplicate?projectId=${projectId}&language=${this.request.language}`;
    this.response.navigate(url);
  }

  async editPreorderConfiguration(preorder) {
    const url = preorder.editConfigurationUrl;
    if (url) {
      this.response.navigate(url);
    } else {
      GuiNotification.modalInfo(
        this.i18n.gettext('Non è possibile modificare questa configurazione.')
      );
    }
  }

  async editPreorderLayout(preorder) {
    const url = preorder.editLayoutUrl;
    if (url) {
      this.response.navigate(url);
    } else {
      const setServiceUrl = `${preorder.setServiceUrl}&hideNotify=true`;
      this.response.navigate(setServiceUrl);
    }
  }

  async editOrCreateLayout(preorder) {
    const url = `${conf.settings.EDITOR_BASE_APPLICATION_URL}home-decor-frame?configurationId=${preorder.configurationGuid}&projectId=${preorder.projectId}&language=${this.request.language}`;
    this.response.navigate(url);
  }

  async viewPreview(preorder) {
    const url = preorder.previewUrl;
    if (url) {
      // this.response.navigate(url);
      window.open(url, '_blank');
    } else {
      GuiNotification.modalInfo(
        this.i18n.gettext('Non è possibile visualizzare questa anteprima.')
      );
    }
  }

  showOrderInWaitingPaymentAlert() {
    this.orderInWaitingPaymentAlertIsActive = true;
    this.render(this.context);
  }

  hideOrderInWaitingPaymentAlert() {
    this.orderInWaitingPaymentAlertIsActive = false;
    this.render(this.context);
  }

  async cancelPayment(cartGuid) {
    this.hideOrderInWaitingPaymentAlert();

    this.startLoadingPage();

    let orderHeader = await this.loadCurrentOrderHeader(cartGuid);

    if (orderHeader) {
      try {
        const { shopCode } = this.customerUser;
        await this.orderHeader.model.destroy({
          data: {
            softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE,
            cartGuid,
            shopCode
          }
        });
      } catch (error) {
        orderHeader = null;
        Logger.error('CartCheckoutBaseController.cancelPayment', {
          error,
          cartGuid,
          orderHeader: this.orderHeader.model
        });
      }
    }

    if (!orderHeader) {
      this.showOrderInWaitingPaymentAlert();
      GuiNotification.modalError(
        this.i18n.gettext("Non è stato possibile ripristinare l'ordine: ") +
          this.cartHeader.model.orderGuid,
        'Error detail: CartCheckoutBaseController.cancelPayment'
      );
      return;
    }

    await this.getCartProjects();

    if (this.request.state === 'checkout:main') {
      this.navigateToCartView();
    } else {
      this.stopLoadingPage();
    }
  }
}
