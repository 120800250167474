import React from 'react';
import { components } from 'outlinejs';
import { AeModal } from '@photosi/albumepoca-ui';
import PropTypes from 'prop-types';

import LayoutPdfIt from '../media/images/pdfs/guida_posizionamento_immagini_it.pdf';
import LayoutPdfEn from '../media/images/pdfs/guida_posizionamento_immagini_en.pdf';
import LayoutPdfEs from '../media/images/pdfs/guida_posizionamento_immagini_es.pdf';

export class DuplicationAlert extends components.BaseComponent {
  constructor(props) {
    super(props);
  }

  confirm() {
    const { onConfirm } = this.props;
    onConfirm();
  }

  close() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { isOpen, language } = this.props;
    const customClasses = {
      'modal-md': true,
      'modal-attention': true
    };
    let downloadLink;
    switch (language) {
      case 'it':
        downloadLink = LayoutPdfIt;
        break;
      case 'es':
        downloadLink = LayoutPdfEs;
        break;
      default:
        downloadLink = LayoutPdfEn;
    }

    const title = this.i18n.gettext('Warning');
    const message = this.i18n.gettext(
      'After duplicating the product, please check the proof of the new copies because by changing the original print size, the trim margins also change.'
    );
    const downloadMessage = this.i18n.gettext('Download the guidelines:');

    const confirmButton = (
      <a className="basic__cta__btns" onClick={this.confirm.bind(this)}>
        {this.i18n.gettext('I understand')}
      </a>
    );

    const downloadBlock = (
      <div className="download-block">
        {downloadMessage}{' '}
        <a href={downloadLink} download>
          {this.i18n.gettext('download pdf')}
        </a>
      </div>
    );

    const template = (
      <div className="custom-template">
        <h4 className="modal-title">{title}</h4>
        <hr />
        {message}
        {downloadBlock}
        <hr />
        <div className="modal-footer">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 text-center" />
            <div className="col-sm-6 col-md-6 col-lg-6 text-center">{confirmButton}</div>
          </div>
        </div>
      </div>
    );

    return (
      <AeModal
        isOpen={isOpen}
        title={title}
        customClasses={customClasses}
        customTemplate={template}
        onClose={this.close.bind(this)}
      />
    );
  }
}

DuplicationAlert.propTypes = {
  isOpen: PropTypes.bool,
  language: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};
