import { runtime } from '@outlinejs/contexts';
import localForage from 'localforage';
import Logger from '../logger';
import { conf } from 'outlinejs';
import Cookies from 'js-cookie';

export async function setLocalForage(key, value) {
  return localForage
    .setItem(key, value)
    .then(() => localForage.getItem(key))
    .then((result) => result)
    .catch((error) => {
      Logger.error('setLocalForage - lib error', {
        error,
        key,
        value
      });
    });
}

export async function getLocalForage(key) {
  return localForage
    .getItem(key)
    .then((result) => result)
    .catch((error) => {
      Logger.error('getLocalForage - lib error', {
        error,
        key
      });
    });
}

export async function deleteLocalForage(key) {
  return localForage
    .removeItem(key)
    .then((object) => object)
    .catch((error) => {
      Logger.error('deleteLocalForage - lib error', {
        error,
        key
      });
    });
}

export class BaseStorage {
  static setLocalForage(key, value) {
    if (runtime.isClient) {
      return setLocalForage(key, value);
    }
    Logger.info('setLocalForage not available on server side');
  }

  static getLocalForage(key) {
    if (runtime.isClient) {
      return getLocalForage(key);
    }
    Logger.info('getLocalForage not available on server side');
  }

  static deleteLocalForage(key) {
    if (runtime.isClient) {
      return deleteLocalForage(key);
    }
    Logger.info('getLocalForage not available on server side');
  }
}

export class LanguageStorage extends BaseStorage {
  static get cookieName() {
    return 'language';
  }

  /*
   * Get Cookie domain from URL and return only the last two parts.
   * example: '.dyn.dom', '.albumepoca.com'
   * */
  static get cookieDomain() {
    const parts = location.hostname.split('.');
    const localEnvironment =
      (parts.length === 4 && /^\d+$/.test(location.hostname.replace(/\./g, ''))) ||
      location.hostname === 'localhost';
    return localEnvironment ? location.hostname : `.${parts.slice(-2).join('.')}`;
  }

  static async setLanguage(language) {
    if (conf.settings.LANGUAGES.indexOf(language) > -1) {
      Cookies.set(this.cookieName, language, {
        expires: 365,
        domain: this.cookieDomain
      });
    } else {
      Logger.info(`La lingua "${language}" non è fra quelle disponibili di questa applicazione.`);
    }
  }

  static async getLanguage() {
    const result = Cookies.get(this.cookieName);
    if (result) {
      return result;
    }

    return conf.settings.DEFAULT_LANGUAGE;
    // let browserLanguage = window.navigator.language.substring(0, 2);
    //
    // let lang = browserLanguage && conf.settings.LANGUAGES.indexOf(browserLanguage) > -1 ? browserLanguage : conf.settings.DEFAULT_LANGUAGE;
    // return lang;
  }
}
