import React from 'react';
import { DidomiSDK } from '@didomi/react';
import { conf } from 'outlinejs';
import EventTracker from '../eventTracker';

export class CookieConsent extends React.Component {
  render() {
    const didomiConsentId = conf.settings.DIDOMI_CONSENT_ID;
    const didomiApiKey = conf.settings.DIDOMI_API_KEY;
    const amplitudeConsentId = conf.settings.AMPLITUDE_CONSENT_ID;

    const handleDidomiReady = (didomi) => {
      console.log('AMP: didomi loaded', didomi);
      const enabled = didomi.getUserConsentStatusForAll().vendors.enabled;
      if (enabled.includes(amplitudeConsentId)) {
        console.log('AMP: amplitude already accepted');
        window.AMPLITUDE_CONSENT_ENABLED = true;
      }

      didomi
        .getObservableOnUserConsentStatusForVendor(amplitudeConsentId)
        .filter((status) => {
          window.AMPLITUDE_CONSENT_ENABLED = status;
          return status === true;
        })
        .first()
        .subscribe((status) => {
          console.log('AMP: first amplitude status change', status);
          EventTracker.initialize();
        });
    };

    if (!didomiConsentId) {
      return <></>;
    }

    return (
      <DidomiSDK
        apiKey={didomiApiKey}
        noticeId={didomiConsentId}
        iabVersion={2}
        embedTCFStub={true}
        gdprAppliesGlobally={true}
        onReady={handleDidomiReady}
      />
    );
  }
}
