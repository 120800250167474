import { defaultCenter } from './notification-center';

export class GuiNotification {
  static get modalErrorEvent() {
    return 'modalError';
  }

  static modalError(
    errorText,
    errorDetail,
    cancelLabel,
    onConfirmCallback = null,
    onCancelCallback = null
  ) {
    defaultCenter.emit(
      this.modalErrorEvent,
      errorText,
      errorDetail,
      cancelLabel,
      onConfirmCallback,
      onCancelCallback
    );
  }

  static get modalWarningCouponEvent() {
    return 'modalWarningCoupon';
  }

  static modalWarningCoupon(content) {
    defaultCenter.emit(this.modalWarningCouponEvent, content);
  }

  static get modalErrorCouponEvent() {
    return 'modalErrorCoupon';
  }

  static modalErrorCoupon(errorTypes, voucher) {
    defaultCenter.emit(this.modalErrorCouponEvent, errorTypes, voucher);
  }

  static get modalInfoEvent() {
    return 'modalInfo';
  }

  static modalInfo(content, title, cancelLabel, onConfirmCallback = null, onCancelCallback = null) {
    defaultCenter.emit(
      this.modalInfoEvent,
      content,
      title,
      cancelLabel,
      onConfirmCallback,
      onCancelCallback
    );
  }

  static get modalConfirmEvent() {
    return 'modalConfirm';
  }

  static modalConfirm(content, confirmCallBack, cancelCallback, title, confirmLabel, cancelLabel) {
    defaultCenter.emit(
      this.modalConfirmEvent,
      content,
      confirmCallBack,
      cancelCallback,
      title,
      confirmLabel,
      cancelLabel
    );
  }

  static get modalDuplicationEvent() {
    return 'modalDuplication';
  }

  static modalDuplication(confirmCallBack) {
    defaultCenter.emit(this.modalDuplicationEvent, confirmCallBack);
  }

  static get modalPreviewEvent() {
    return 'modalPreview';
  }

  static modalPreview(imagePreviewUrl, svgPreview, enableDownload) {
    defaultCenter.emit(this.modalPreviewEvent, imagePreviewUrl, svgPreview, enableDownload);
  }

  static get modalWithHideButtonEvent() {
    return 'modalWithHideButton';
  }

  static modalWithHideButton(title, message, storageKey) {
    defaultCenter.emit(this.modalWithHideButtonEvent, title, message, storageKey);
  }
}
