import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { conf, components } from 'outlinejs';

import Cookies from 'js-cookie';

/**
 * Coupon Component.
 */
const MODAL_STYLE = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.45)',
    zIndex: '1050' // default bootstrap modal z-index value
  }
};

const BLOCKING_DIALOG_KEY = '__hideNewCartDialog';
const BLOCKING_DIALOG_EXPIRE_DAYS = 30;

export class BlockingAlertComponent extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: props.isActive,
      modalIsOpen: props.isOpen,
      blockingDialogCheckBox: null,
      messageLinkUrl: null,
      closeStateChange: false
    };
  }

  UNSAFE_componentWillReceiveProps() {
    if (!this.state.closeStateChange) {
      this.setState({
        isActive: this.props.isActive,
        modalIsOpen: this.props.isOpen
      });
    }
    this.showBlockingMessageDialog();
    if (this.request.language) {
      this.setState({
        messageLinkUrl: this.getPricesUrl(this.request.language)
      });
    }
  }

  componentDidMount() {
    this.showBlockingMessageDialog();
  }

  getPricesUrl(language) {
    return conf.settings.PRICES_URLS[language];
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      closeStateChange: true
    });
  }

  async dismissModal() {
    this.closeModal();
    if (this.props.onDismiss) {
      await this.props.onDismiss();
    }
  }

  getMantainanceMessage() {
    let msg = this.i18n.gettext(
      "We're undergoing a bit of scheduled maintenance. Sorry for the inconvenience. We'll be back up and running at HH:MM am (CET) "
    );
    msg = msg.replace('HH:MM', '14:00');

    return msg;
  }

  //eslint-disable-next-line
  getOnEnterMessage(url) {
    switch (this.request.language) {
      case 'it':
        return "Un nuovo carrello: più pratico e più veloce. Abbiamo ridotto i tempi di caricamento grazie ad un'interfaccia che ti permette di consultare solo i dettagli dei prodotti che ti interessano.";
      case 'es':
        return 'Un nuevo carrito: más practico y veloz. Hemos reducido los tiempos de carga a través de una interfaz que te permite consultar solo los detalles de los productos en los que estás ineteresado.';
      default:
        return "A new cart: faster and more practical. We reduced the loading times thanks to a new interface that allows you to check only the  details of the products you're interested in.";
    }
  }

  showBlockingMessageDialog() {
    const blockingDialog = Cookies.get(BLOCKING_DIALOG_KEY);

    if (blockingDialog) {
      this.setState({
        modalIsOpen: false
      });
    }
  }

  onChangeBlockingDialogVisibility() {
    this.setState({
      blockingDialogCheckBox: !this.state.blockingDialogCheckBox
    });
    if (!this.state.blockingDialogCheckBox) {
      Cookies.set(BLOCKING_DIALOG_KEY, 'yes', {
        expires: BLOCKING_DIALOG_EXPIRE_DAYS,
        domain: conf.settings.CART_DOMAIN
      });
    } else {
      Cookies.remove(BLOCKING_DIALOG_KEY, {
        domain: conf.settings.CART_DOMAIN
      });
    }
  }

  render() {
    const { title, blockingClass, dismissLabel } = this.props;
    const content = this.state.isActive
      ? this.getMantainanceMessage(this.state.messageLinkUrl)
      : this.getOnEnterMessage(this.state.messageLinkUrl);
    const closeButton = this.state.isActive ? null : (
      <button
        type="button"
        className="close"
        onClick={this.closeModal.bind(this)}
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    );

    const dismissButton = this.state.isActive ? null : (
      <a className="basic__cta__btns" onClick={this.dismissModal.bind(this)}>
        {dismissLabel}
      </a>
    );

    const checkBox = this.state.isActive ? null : (
      <div>
        <form className="form-inline">
          <input
            type="checkbox"
            checked={this.state.blockingDialogCheckBox ? this.state.blockingDialogCheckBox : null}
            onChange={this.onChangeBlockingDialogVisibility.bind(this)}
          />
          <span className="help-block" style={{ paddingLeft: '5px' }}>
            {this.i18n.gettext("Doesn't show this message again")}
          </span>
        </form>
      </div>
    );

    const modalBody = (
      <div className="modal-content">
        <div className="modal-header">
          {closeButton}
          <h4 className="modal-title">{title}</h4>
        </div>

        <div className="modal-body">{content}</div>

        <div className="modal-footer">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 text-center" />
            <div className="col-sm-6 col-md-6 col-lg-6 text-center">{dismissButton}</div>
          </div>
          {checkBox}
        </div>
      </div>
    );

    return (
      <div>
        <Modal
          className={`Modal__Bootstrap modal-dialog ${blockingClass}`}
          isOpen={this.state.isActive ? this.state.isActive : this.state.modalIsOpen}
          style={MODAL_STYLE}
          ariaHideApp={false}>
          {modalBody}
        </Modal>
      </div>
    );
  }
}

BlockingAlertComponent.propTypes = {
  /**
   * Html template for modal
   */
  // content: PropTypes.string,
  /**
   * Title
   */
  title: PropTypes.string,
  /**
   * Custom modal class
   */
  blockingClass: PropTypes.string,
  /**
   * Dismiss label
   */
  dismissLabel: PropTypes.string
};
